import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { Row, Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  useGridApiRef
} from '@mui/x-data-grid-premium';
import { ptBR } from '@mui/x-data-grid/locales';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const DBGrid = (props) => {
  const apiRef = useGridApiRef();
  const [corselec1, setCorselec1] = React.useState('#ccdef7');
  const [corselec2, setCorselec2] = React.useState('#ccdef7');
  const [corselec3, setCorselec3] = React.useState('#ccdef7');
  const [corselec4, setCorselec4] = React.useState('#ccdef7');
  const [corselec5, setCorselec5] = React.useState('#ccdef7');
  const [corselec6, setCorselec6] = React.useState('#ccdef7');
  const [corselec7, setCorselec7] = React.useState('#ccdef7');
  const [corselec8, setCorselec8] = React.useState('#ccdef7');
  const [corselec9, setCorselec9] = React.useState('#ccdef7');

  const [corline1, setCorline1] = React.useState('#000');
  const [corline2, setCorline2] = React.useState('#000');
  const [corline3, setCorline3] = React.useState('#000');
  const [corline4, setCorline4] = React.useState('#000');
  const [corline5, setCorline5] = React.useState('#000');
  const [corline6, setCorline6] = React.useState('#000');
  const [corline7, setCorline7] = React.useState('#000');
  const [corline8, setCorline8] = React.useState('#000');
  const [corline9, setCorline9] = React.useState('#000');
  const [selectedRowId, setSelectedRowId] = React.useState(0);
  const [isselect, setIselect] = React.useState(false);

  const {
    id,
    key,
    width,
    heigth,
    rows,
    columns,
    loading,
    onKeyDown,
    onCelClick,
    onDoubleClick,
    multselec,
    onMultselec,
    validations,
    disableOrdem,
    item,
    setItem,
    rowHeight,
    disabled,
    focus,
    forcefocus
  } = props;

  useEffect(() => {
    if (apiRef.current && rows.length > 0 && focus) {
      if (!isselect || forcefocus) {
        apiRef.current.setCellFocus(0, columns[0].field);
        setIselect(true);
      }
    }
  }, [apiRef, rows]);

  const ValidColor = (value, valueref, sinal, color) => {
    let colorreturn = undefined;
    let valida = false;
    switch (sinal) {
      case 1: {
        valida = value == valueref;
        colorreturn = color;
        break;
      }
      case 2: {
        valida = value != valueref;
        colorreturn = color;
        break;
      }
      case 3: {
        valida = value > valueref;
        colorreturn = color;
        break;
      }
      case 4: {
        valida = value < valueref;
        colorreturn = color;
        break;
      }
      case 5: {
        valida = value >= valueref;
        colorreturn = color;
        break;
      }
      case 6: {
        valida = value <= valueref;
        colorreturn = color;
        break;
      }
      case 7: {
        valida = value.includes(valueref);
        colorreturn = color;
        break;
      }
      case 8: {
        valida = !value.includes(valueref);
        colorreturn = color;
        break;
      }
    }
    if (valida) {
      return colorreturn;
    } else {
      return undefined;
    }
  };

  const SetColor = (index, color) => {
    switch (index) {
      case 0: {
        setCorselec1(color);
        break;
      }
      case 1: {
        setCorselec2(color);
        break;
      }
      case 2: {
        setCorselec3(color);
        break;
      }
      case 3: {
        setCorselec4(color);
        break;
      }
      case 4: {
        setCorselec5(color);
        break;
      }
      case 5: {
        setCorselec6(color);
        break;
      }
      case 6: {
        setCorselec7(color);
        break;
      }
      case 7: {
        setCorselec8(color);
        break;
      }
      case 8: {
        setCorselec9(color);
        break;
      }
    }
    return index + 1;
  };

  const SetColorline = (index, color) => {
    switch (index) {
      case 0: {
        setCorline1(color);
        break;
      }
      case 1: {
        setCorline2(color);
        break;
      }
      case 2: {
        setCorline3(color);
        break;
      }
      case 3: {
        setCorline4(color);
        break;
      }
      case 4: {
        setCorline5(color);
        break;
      }
      case 5: {
        setCorline6(color);
        break;
      }
      case 6: {
        setCorline7(color);
        break;
      }
      case 7: {
        setCorline8(color);
        break;
      }
      case 8: {
        setCorline9(color);
        break;
      }
    }
    return index + 1;
  };

  columns.forEach((column) => {
    const item = (params) => {
      return Number(params).toFixed(column.decimal);
    };

    if (column.type === 'number') {
      column['valueFormatter'] = item;
    }

    if (column.type === 'date') {
      column['valueGetter'] = (params) => {
        try {
          const [day, month, yearTime] = params.split('/');
          const [year] = yearTime.split(' ');
          return new Date(`${year}-${month}-${day}`);
        } catch (error) {
          console.log(error);
          return params;
        }
      };
    }

    if (column.type === 'dateTime') {
      column['valueGetter'] = (params) => {
        if (params !== undefined && params !== null) {
          try {
            const [day, month, yearTime] = params.split('/');
            const [year, time] = yearTime.split(' ');
            return new Date(`${year}-${month}-${day}T${time}`);
          } catch (error) {
            console.log(error);
            return params;
          }
        }
      };
    }
  });

  return (
    <Box
      sx={{
        height: heigth,
        width: width,
        fontFamily: 'Roboto, sans-serif', // Substitua com a fonte desejada
        fontSize: '8px', // Tamanho da fonte (opcional)
        '& .corselec1': {
          backgroundColor: corselec1,
          color: corline1
        },
        '& .corselec2': {
          backgroundColor: corselec2,
          color: corline2
        },
        '& .corselec3': {
          backgroundColor: corselec3,
          color: corline3
        },
        '& .corselec4': {
          backgroundColor: corselec4,
          color: corline4
        },
        '& .corselec5': {
          backgroundColor: corselec5,
          color: corline5
        },
        '& .corselec6': {
          backgroundColor: corselec6,
          color: corline6
        },
        '& .corselec7': {
          backgroundColor: corselec7,
          color: corline7
        },
        '& .corselec8': {
          backgroundColor: corselec8,
          color: corline8
        },
        '& .corselec9': {
          backgroundColor: corselec9,
          color: corline9
        },
        '& .selected-row': {
          backgroundColor: 'rgb(0, 102, 255)',
          color: '#fff'
        }
      }}
    >
      <div
        style={{
          height: '100%',
          width: '100%',
          pointerEvents: disabled ? 'none' : 'auto',
          opacity: disabled ? 0.5 : 1
        }}
      >
        <DataGridPremium
          id={id}
          key={key}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={rows}
          columns={columns}
          apiRef={apiRef}
          sx={{
            fontFamily: 'Lobster, sans-serif', // Substitua com a fonte desejada
            fontSize: '14px' // Tamanho da fonte (opcional)
          }}
          density="compact"
          loading={loading}
          checkboxSelection={multselec}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15
              }
            }
          }}
          disableColumnSorting={disableOrdem}
          pageSizeOptions={[10, 15, 20, 25, 50, 100]}
          rowHeight={rowHeight !== undefined ? rowHeight : 38}
          slotProps={{
            row: {
              onFocus: (event) => {
                const newRow = event.currentTarget.getAttribute('data-id');
                try {
                  setSelectedRowId(rows[newRow].id);
                  setItem(rows[newRow]);
                } catch (error) {
                  //console.log(error)
                }
              }
            }
          }}
          slots={{
            toolbar: CustomToolbar
          }}
          onCellDoubleClick={(newSelection) => {
            if (item !== undefined) {
              setItem(newSelection.row);
            }
            if (onDoubleClick !== undefined) {
              onDoubleClick(newSelection);
            }
          }}
          onCellClick={(newSelection) => {
            if (item !== undefined) {
              setItem(newSelection.row);
            }
            if (onCelClick !== undefined) {
              onCelClick(newSelection);
            }
          }}
          onCellKeyDown={(newSelection, event) => {
            if (item !== undefined) {
              setItem(newSelection.row);
            }
            if (onKeyDown !== undefined) {
              onKeyDown(newSelection, event);
            }
          }}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            if (onMultselec !== undefined) {
              onMultselec(newRowSelectionModel);
            }
          }}
          onRowClick={(params) => setSelectedRowId(params.id)}
          getCellClassName={(params) => {
            if (validations !== undefined) {
              let valida = false;
              let valueref = '';
              let value = '';
              let posindex = 1;
              validations.forEach((object, index) => {
                if (!valida) {
                  value = params.row[object.campo];
                  if (object.tipotab !== 'G') {
                    if (object.tipotab === 'V') {
                      valueref = object.valorval;
                    } else {
                      valueref = params.row[object.camval1];
                    }
                    let colorfim = ValidColor(value, valueref, object.sinal, object.cor);
                    if (colorfim !== undefined) {
                      posindex = SetColor(index, colorfim);
                      valida = true;
                    }
                  } else {
                    for (var i = 0; i < object.total; i++) {
                      let value = params.row[object.campo[i]];
                      let valoritem = object.valorval[i];
                      let sinalitem = object.sinal[i];
                      let coritem = object.cor[i];
                      let colorfim = ValidColor(value, valoritem, sinalitem, coritem);
                      if (colorfim !== undefined) {
                        posindex = SetColor(i, colorfim);
                        valida = true;
                        try {
                          let corline = object.corline[i];
                          SetColorline(i, corline);
                        } catch (error) {
                          //console.log('Não foi definido cor para as linhas !');
                        }
                      }
                    }
                  }
                }
              });
              if (params.id !== selectedRowId) {
                if (valida) {
                  return 'corselec' + posindex;
                } else {
                  if (params.row.id % 2 === 0) {
                    return 'text-list';
                  } else {
                    return 'odd';
                  }
                }
              } else {
                return 'selected-row';
              }
            } else {
              if (params.id !== selectedRowId) {
                if (params.row.id % 2 === 0) {
                  return 'text-list';
                } else {
                  return 'odd';
                }
              } else {
                return 'selected-row';
              }
            }
          }}
        />
      </div>
    </Box>
  );
};

export function FieldText({ text, maxLength, marginCenter }) {
  const [showtext, setShowtext] = React.useState(false);

  const handleClosetext = () => {
    setShowtext(false);
  };

  return (
    <React.Fragment>
      <div>
        {text.length > maxLength ? (
          <Row
            style={{
              width: '50px',
              height: '50px',
              textAlign: 'center',
              position: 'absolute',
              verticalAlign: 'middle',
              marginTop: marginCenter
            }}
          >
            <Button id="btnExpandir" className="btn-icon btn-sucess shadow-2 mb-3" onClick={() => setShowtext(true)}>
              <i className={'feather icon-search'} />
            </Button>
          </Row>
        ) : (
          <></>
        )}
      </div>
      <Modal backdrop="static" size="xl" show={showtext} centered={true} onHide={handleClosetext}>
        <Modal.Header className="h5" closeButton>
          <i className={'feather icon-file-text h1'} />
          &nbsp;Visualização do Texto
        </Modal.Header>
        <ModalBody>
          <textarea
            className="form-control"
            value={text}
            onChange={(e) => handleChangefield(e, props.index)}
            rows={30}
            readOnly={true}
          ></textarea>
        </ModalBody>
        <ModalFooter>
          <Button id="btnFecharhist" className="btn btn-primary shadow-2 mb-3" onClick={(e) => setShowtext(false)}>
            <i className={'feather icon-log-out'} /> Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default DBGrid;
