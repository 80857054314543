import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { LinearProgress } from '@mui/material';
import { apiBrowse, apiList } from '../../api/crudapi';
import { calcObject } from '../../utils/calcObject';
import { CreateObject } from '../CreateObject';
import { Decode64 } from '../../utils/crypto';
import DBGrid from '../DBGrid';

const Browse = (props) => {
  const [fields, setFields] = React.useState([]);
  const [carregando, setCarregando] = React.useState(false);
  const [valuesfield, setValuesfield] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [rowsfields, setRowsfields] = React.useState([]);
  const [colfields, setColfields] = React.useState([]);
  const [rowsselect, setRowsselect] = React.useState([]);
  const [selecao, setSelecao] = React.useState([]);
  const [namefields, setNamefields] = React.useState(props.fields);

  const [showfields, setShowfields] = useState(false);

  const { showbrowse, setShowbrowse } = props;
  const { itemselec, setItemselec } = props;
  const { codigos, setCodigos } = props;
  const { nomes, setNomes } = props;

  const Processar = () => {
    setCarregando(true);
    setColfields([
      { headerClassName: 'header-list', field: 'namefield', headerName: 'Campo', width: 170 },
      { headerClassName: 'header-list', field: 'headerName', headerName: 'Descrição Campo', width: 630 },
      { headerClassName: 'header-list', field: 'type', headerName: 'Tipo', width: 150 },
      { headerClassName: 'header-list', field: 'size', headerName: 'Tamanho', width: 100 }
    ]);
    apiBrowse(props.object, namefields, 'S').then((response) => {
      if (response.status === 200) {
        setFields(response.data);
        setCarregando(false);
      }
    });
  };

  useEffect(() => {
    setNamefields(props.fields);
    Processar();
  }, []);

  const handleClosefields = () => {
    setShowfields(false);
    Processar();
  };

  useEffect(() => {
    Processar();
  }, [namefields]);

  useEffect(() => {
    calcObject(fields, 70);
    filtrar();
    try {
      document.getElementById('nome').focus();
    } catch (error) {
      ////console.log(error);
    }
  }, [fields]);

  const filtrar = () => {
    let filter = '(' + props.table + "_SITUACAO = 'A') ";
    if (Decode64(sessionStorage.getItem('manager')) === 'N' && Decode64(sessionStorage.getItem('seller')) !== 'ZZZZ') {
      if (props.table === 'TB01008' || props.table === 'TB01127') {
        if (!filter.includes('VENDEDOR')) {
          filter = filter + ' and (' + props.table + "_VENDEDOR = '" + Decode64(sessionStorage.getItem('seller')) + "') ";
        }
      }
      if (props.table === 'TB02255' || props.table === 'TB02264') {
        if (!filter.includes('CODVEN')) {
          filter = filter + ' and (' + props.table + "_CODVEN = '" + Decode64(sessionStorage.getItem('seller')) + "') ";
        }
      }
    }

    let filteraux = props.filteraux;
    if (filteraux !== undefined && filteraux !== '' && filteraux !== null) {
      filter = filter + filteraux;
    }
    if (props.table === 'TB02176' && parseInt(sessionStorage.getItem('perfil')) === 1) {
      filter = filter + " and TB02176_CODIGO = '" + Decode64(sessionStorage.getItem('temple')) + "' ";
    }
    if (props.table === 'TB01008' && parseInt(sessionStorage.getItem('perfil')) === 2) {
      filter = filter + " and TB01008_CODIGO = '" + Decode64(sessionStorage.getItem('partner')) + "' ";
    }

    fields.forEach((field, index) => {
      if (valuesfield[index] !== '' && valuesfield[index] !== undefined) {
        filter = filter + ' and (' + field.namefield;
        if (field.pk === true) {
          filter = filter + " = '" + valuesfield[index] + "' ";
        } else {
          filter = filter + " like '" + valuesfield[index] + "%' ";
        }
        filter = filter + ')';
      }
    });
    setCarregando(true);
    apiList(props.classobject, props.fields, '', filter).then((response) => {
      if (response.status === 200) {
        setRows(response.data);
        setCarregando(false);
      }
    });
  };

  const limpar = () => {
    fields.forEach((field, index) => {
      valuesfield[index] = '';
    });
    setRows([]);
  };

  const addField = () => {
    setCarregando(true);
    apiBrowse(props.object, namefields, 'N').then((response) => {
      if (response.status === 200) {
        setRowsfields(response.data);
        setCarregando(false);
      }
    });
    setShowfields(true);
  };

  const aplicarField = () => {
    if (rowsselect.length > 0) {
      let sfields = namefields;
      rowsselect.forEach((index) => {
        const field = rowsfields[index - 1];
        sfields = sfields + ',' + field.namefield;
      });
      setNamefields(sfields);
    }
    limpar();
    setShowfields(false);
  };

  const addSelection = () => {
    if (selecao.length > 0) {
      let scodigos = '';
      let snomes = '';
      selecao.forEach((index) => {
        const item = rows[index - 1];
        scodigos = scodigos + "'" + item.codigo + "'" + ',';
        snomes = snomes + "'" + item.nome + "'" + ',';
      });
      setCodigos('(' + scodigos.substring(0, scodigos.length - 1) + ')');
      setNomes('(' + snomes.substring(0, snomes.length - 1) + ')');
    } else {
      setCodigos('');
      setNomes('');
    }
    setShowbrowse(false);
  };

  const clickGrid = (newSelection) => {
    if (props.tipo === '1') {
      setItemselec(newSelection.row);
    }
  };

  const keyGrid = (newSelection, event) => {
    if (props.tipo === '1') {
      setItemselec(newSelection.row);
      if (event.key === 'Enter') {
        setShowbrowse(true);
      }
    }
  };

  const dblClickGrid = (newSelection) => {
    if (props.tipo === '1') {
      setItemselec(newSelection.row);
      setShowbrowse(false);
    }
  };
  return (
    <React.Fragment>
      <div id="linear-progress">{carregando && <LinearProgress color="primary" />}</div>
      <Row style={{ marginBottom: '5px' }}>
        {fields.map((field, index) => (
          <CreateObject
            key={index}
            field={field}
            index={index}
            valuesfield={valuesfield}
            setValuesfield={(data) => setValuesfield(data)}
            fields={fields}
            invisible={false}
          ></CreateObject>
        ))}
      </Row>
      <DBGrid
        width="100%"
        heigth="460px"
        rows={rows}
        columns={fields}
        loading={carregando}
        onKeyDown={keyGrid}
        onDoubleClick={dblClickGrid}
        onCelClick={clickGrid}
        multselec={props.tipo === '2'}
        onMultselec={setSelecao}
      ></DBGrid>
      <Row style={{ textAlign: 'right', marginTop: '5px' }}>
        <Col>
          <Button id="btnFiltrar" onClick={filtrar} className="btn btn-success shadow-2 mb-3">
            <i className={'feather icon-filter'} /> Fitrar
          </Button>
          <Button id="btnlimpar" onClick={limpar} className="btn btn-warning shadow-2 mb-3">
            <i className={'feather icon-refresh-cw'} /> Limpar
          </Button>
          <Button id="btnAdd" className="btn btn-primary shadow-2 mb-3" onClick={() => addField()}>
            <i className={'feather icon-star'} /> Add. Campos
          </Button>
          {props.tipo === '2' ? (
            <Button id="btnAplicarselec" className="btn btn-primary shadow-2 mb-3" onClick={() => addSelection()}>
              <i className={'feather icon-check-square'} /> Aplicar seleção
            </Button>
          ) : (
            <div></div>
          )}
        </Col>
      </Row>
      <Modal backdrop="static" size="xl" show={showfields} centered={true} onHide={handleClosefields}>
        <Modal.Header className="h5" closeButton>
          <i className={'feather icon-star h1'} />
          &nbsp;Novo campo
        </Modal.Header>
        <Modal.Body>
          <DBGrid
            width="100%"
            heigth="460px"
            rows={rowsfields}
            columns={colfields}
            loading={carregando}
            multselec={true}
            onMultselec={setRowsselect}
          ></DBGrid>
        </Modal.Body>
        <ModalFooter>
          <Row style={{ textAlign: 'rigth' }}>
            <Col>
              <Button id="btnAplicar" className="btn btn-success shadow-2 mb-2" onClick={() => aplicarField()}>
                <i className={'feather icon-check'} /> Aplicar
              </Button>
              <Button id="btnCancelar" className="btn btn-warning shadow-2 mb-2" onClick={() => setShowfields(false)}>
                <i className={'feather icon-x'} />
                Cancelar
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default Browse;
